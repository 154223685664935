import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { MenuItem, Select } from "@mui/material"
import { useEffect } from "react"
import { fetchUsersThunk } from "../../users-list/UserListSlice"
import { useNavigate } from "react-router-dom"

export default function CardListUserSelector({ userId = "" }: { userId?: string }) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { users } = useAppSelector((state: RootState) => state.users)
  const { currentUser } = useAppSelector((state: RootState) => state.login)

  useEffect(() => {
    if (users.length === 0 && currentUser?.level === 0) {
      dispatch(fetchUsersThunk())
    }
  }, [users])

  const chooseUSerEvent = (e: any) => {
    const userId = e.target.value
    if (userId !== "") {
      navigate(`/dashboard/cards-lists/${userId}`)
    }
  }
  return (
    <>
      {users.length > 0 && <Select
        placeholder="Usuario"
        fullWidth
        onChange={chooseUSerEvent}
        value={userId} 
      >cd 
        <MenuItem value={""}>-- USUARIO --</MenuItem>
        {users.map((el, i) => {
          return (
            <MenuItem key={`selectorCardsList${el._id}`} value={el._id}>
              {el.user || el.email}
            </MenuItem>
          )
        })}
      </Select>}
    </>
  )
}
