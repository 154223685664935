import { useNavigate } from "react-router-dom"
import { CheckUserAllowedComponent } from "../../app/components/check-user-allowed-component"
import UsersListTable from "./components/users-list-table"
import { ThemeProvider } from "@emotion/react"
import DenseTheme from "../../app/theme/dense-theme"

export default function UsersList() {
  const navigate  = useNavigate()

  const notAllowed = (allowed: boolean) => {
    if(!allowed){
      navigate("/dashboard")
    }
  }
  return (
    <ThemeProvider theme={DenseTheme}>
      <CheckUserAllowedComponent checkIfAdmin={true} onCheckPermission={notAllowed}>
        <UsersListTable />
      </CheckUserAllowedComponent>
    </ThemeProvider>
  )
}
