import { AxiosError, AxiosResponse } from "axios"
import axios from "axios"
import { KhasWebConstants } from "./khas-web-constants"
import { store } from "./store"
import { endSessionForceUserAction } from "../features/signin/signin.slice"
const urlApi = import.meta.env.VITE_API_URL
const apiAxios = axios.create({
  baseURL: urlApi,
  headers: {
    "Content-type": "application/json",
  },
})

export default class Api {
  async get({ path, data }: { path: string; data?: object }) {
    const token = await this.getToken()
    //console.log({ token })
    const headers = { token: token! }
    try {
      const responseGet: AxiosResponse = await apiAxios.get(path, {
        params: data,
        headers,
      })
      return responseGet.data
    } catch (error) {
      const e = error as AxiosError
      if (
        e.response?.status === 401 &&
        e.response?.data !== undefined &&
        e.response?.data !== undefined
      ) {
        const data: any = e.response?.data
        if (data.error != null && data.error === "Unauthorized") {
          store.dispatch(endSessionForceUserAction())
          localStorage.removeItem("auth")
        }
      }
    }
  }

  async post({ path, data }: { path: string; data: object }) {
    try {
      const token = await this.getToken()
      const headers = { token: token! }
      const responsePost: AxiosResponse = await apiAxios.post(path, data, {
        headers,
      })
      //console.log({responsePost});
      return await responsePost.data
    } catch (error) {
      const e = error as AxiosError
      if (
        e.response?.status === 401 &&
        e.response?.data !== undefined &&
        e.response?.data !== undefined
      ) {
        const data: any = e.response?.data
        if (data.error != null && data.error === "Unauthorized") {
          store.dispatch(endSessionForceUserAction())
          localStorage.removeItem("auth")
        }
      }
    }
  }

  async put({ path, data }: { path: string; data: object }) {
    try {
      const token = await this.getToken()
      const headers = { token: token! }
      const responsePut: AxiosResponse = await apiAxios.put(path, data, {
        headers,
      })
      //console.log({responsePut});
      return await responsePut.data
    } catch (error) {
      const e = error as AxiosError
      if (
        e.response?.status === 401 &&
        e.response?.data !== undefined &&
        e.response?.data !== undefined
      ) {
        const data: any = e.response?.data
        if (data.error != null && data.error === "Unauthorized") {
          store.dispatch(endSessionForceUserAction())
          localStorage.removeItem(KhasWebConstants.localstorageAuthKey)
        }
      }
    }
  }
  
  async patch({ path, data }: { path: string; data: object }) {
    try {
      const token = await this.getToken()
      const headers = { token: token! }
      const responsePut: AxiosResponse = await apiAxios.put(path, data, {
        headers,
      })
      //console.log({responsePut});
      return await responsePut.data
    } catch (error) {
      const e = error as AxiosError
      if (
        e.response?.status === 401 &&
        e.response?.data !== undefined &&
        e.response?.data !== undefined
      ) {
        const data: any = e.response?.data
        if (data.error != null && data.error === "Unauthorized") {
          store.dispatch(endSessionForceUserAction())
          localStorage.removeItem(KhasWebConstants.localstorageAuthKey)
        }
      }
    }
  }

  async delete({ path, data }: { path: string; data?: object }) {
    try {
      const token = await this.getToken()
      const headers = { token: token! }
      const responseDelete: AxiosResponse = await apiAxios.delete(path, {
        data: data,
        headers,
      })
      //console.log({responseDelete});
      return await responseDelete.data
    } catch (error) {
      const e = error as AxiosError
      if (
        e.response?.status === 401 &&
        e.response?.data !== undefined &&
        e.response?.data !== undefined
      ) {
        const data: any = e.response?.data
        if (data.error != null && data.error === "Unauthorized") {
          store.dispatch(endSessionForceUserAction())
          localStorage.removeItem(KhasWebConstants.localstorageAuthKey)
        }
      }
    }
  }

  getToken(): string | null {
    return localStorage.getItem(KhasWebConstants.localstorageTokenKey)
  }
}
