import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ClosuresState } from "./closures_state";
import { getCurrenDateUtil } from "../../../utils/date.utils";

const initialState: ClosuresState = {
  loading: false,
  filter: {
    endDate: getCurrenDateUtil(),
    startDate: getCurrenDateUtil(),
    userId: ""
  }
}
export const thunkAsync = createAsyncThunk( "Closures/fetchCount", async (amount: number) => await setTimeout(() => {}, 2000));

export const ClosuresSlice = createSlice({
  name: "Closures",
  initialState,
  reducers: {
    incrementByAmount: (state, action: PayloadAction<any>) => {
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkAsync.fulfilled, (state, action) => {
    })
    builder.addMatcher((action) => action.type.endsWith("/pending") && action.type.includes("Closures"), (state) => {
      state.loading = true
    }).addMatcher((action) => action.type.endsWith("/fulfilled") && action.type.includes("Closures"), (state) => {
      state.loading = false
    })
  },
})
export const { incrementByAmount } =ClosuresSlice.actions
export default ClosuresSlice.reducer