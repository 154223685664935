import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import esLocale from './locale/es_locale.json';

const localeApp = i18next.use(initReactI18next).init({
  lng: 'es',
  fallbackLng: 'es',
  resources: {
    es: esLocale
  }
})

export default localeApp;