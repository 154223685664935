/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { siginReq } from "../../app/services/users.service"
import { KhasWebConstants } from "../../app/khas-web-constants"
import UserInterface from "../../app/models/user-interface"

export interface SliceNameState {
  loading: boolean
  success?: boolean
  currentUser?: UserInterface
  logout: boolean
  endSession: boolean
  endSessionForce: boolean
}

const initialState: SliceNameState = {
  loading: false, logout: false, endSession: false, endSessionForce: false
}

export const signInUserThunk = createAsyncThunk( "siginslice/signin", async ({user, password}: {user: string, password: string}) => {
  const response = await siginReq(user, password)
  console.log({response});
  
  return response
},
)


export const signinSlice = createSlice({
  name: "siginslice",
  initialState,
  reducers: {
    setLoadingSigin: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    endSessionForceUserAction: (state) => {
      state.endSessionForce = true
    },
    resetEndSessionForce: (state) => {
      state.endSessionForce = false
    },
    logoutAction: (state) => {
      localStorage.removeItem(KhasWebConstants.localstorageAuthKey);
      localStorage.removeItem(KhasWebConstants.localstorageTokenKey)
      state.success = false
      state.endSessionForce = true
      state.logout = true
    },
    checkUserAtLocalStorageAction: (state) => {
      const dataUser = localStorage.getItem(KhasWebConstants.localstorageAuthKey);
      if(dataUser){
        state.currentUser = JSON.parse(dataUser);
      } else {
        state.endSession = true
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(signInUserThunk.pending, (state) => {state.loading = true})
    builder.addCase(signInUserThunk.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false
      const responseSigning = action.payload
      
      if(responseSigning === false){
        state.success = false
      }else {
        const { token } = responseSigning
        console.log({responseSigning});
        localStorage.setItem(KhasWebConstants.localstorageAuthKey, JSON.stringify(responseSigning));
        localStorage.setItem(KhasWebConstants.localstorageTokenKey, token)
        state.currentUser = responseSigning
        state.success = true
      }
      console.log({responseSigning});
  })
    
  },
})

export const { setLoadingSigin, logoutAction, checkUserAtLocalStorageAction, endSessionForceUserAction, resetEndSessionForce } = signinSlice.actions

export default signinSlice.reducer
