import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import usersListReducer from "../features/users-list/UserListSlice"
import handleUserSlice from "../features/handle-user/handle-user.slice"
import SigInReducer from "../features/signin/signin.slice"
import dashboardSlice from "../features/dashboard/dashboard.slice"
import handleCardSlice from "../features/handle-card/handle-card.slice"
import cardsListSlice from "../features/cards-list/cards-list.slice"
import handleExpensesSlice from "../features/handle-expenses/handle-expenses.slice"
import addPaymentSlice from "../features/add-payment/add-payment.slice"
import historyCardsSlice from "../features/history-cards/history-cards.slice"
import capitalContributeSlice from "../features/capital-contribute/capital-contribute.slice"
import closureSlice from "../features/closure/closure.slice"
import closuresSlice from "../features/closures/business_logic/closures.slice"

export const store = configureStore({
  reducer: {
    counter: counterReducer,

    addPayment: addPaymentSlice,
    capitalContribute: capitalContributeSlice,
    cardsList: cardsListSlice,
    closure: closureSlice,
    closuresHistory: closuresSlice,
    dashboard: dashboardSlice,
    expenses: handleExpensesSlice,
    handleUser: handleUserSlice,
    handleCard: handleCardSlice,
    historyCards: historyCardsSlice,
    login: SigInReducer,
    users: usersListReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
