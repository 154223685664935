import { Grid, Paper } from "@mui/material";
import AppDateRangeSelector from "../../../app/components/app-date-range-selector";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import moment from "moment";
import { useEffect } from "react";
import { fetchUsersThunk } from "../../users-list/UserListSlice";
import AppSelector from "../../../app/components/app-selector";

export default function ClosuresHistoryFilter() {  
  const dispatch = useAppDispatch()
  const { filter } = useAppSelector((state) => state.closuresHistory)
  const { users,  } = useAppSelector((state) => state.users)
  const { endDate, startDate, userId } = filter

  useEffect(() => {
    dispatch(fetchUsersThunk())
  }, [])
  return (  
    <>
      <Paper sx={{padding: 2, marginBottom: 1}}>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12}>
            <AppDateRangeSelector 
              id={"closure-range-date-filter"}
              dateEnd={moment(endDate).toDate()} dateStart={moment(startDate).toDate()}
              onChange={() => {}} 
              />
          </Grid>
            <Grid item md={4} xs={12}>
              <AppSelector 
                label="Usuario"
                options={users.map((user) => ({value: `${user.name} ${user.lastName}`, name: user.user}))}
              />
            </Grid>
        </Grid>
      </Paper>
    </>
  )
}