import { Edit, Route, Settings, Shield } from "@mui/icons-material"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"
import { useEffect } from "react"
import { fetchUsersThunk } from "../UserListSlice"
import { Link, useNavigate } from "react-router-dom"

export default function UsersListTable(){

  const { users } = useAppSelector((state: RootState) => state.users)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  
  useEffect(() => {
    if(users.length === 0){
      dispatch(fetchUsersThunk())
    }
  }, [users])

  const goToUserList = (userId: string) => navigate(`/dashboard/cards-lists/${userId}`)

  const padd0 = {padding: "2px"}

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> <Settings /> </TableCell>
              <TableCell>L</TableCell>
              <TableCell>EMAIL</TableCell>
              <TableCell>PHONE</TableCell>
              <TableCell> Lista </TableCell>
              <TableCell> Gastos </TableCell>
              <TableCell> Cuadre </TableCell>
              <TableCell> Terminadas </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((el, i) => {
              return (
                <TableRow key={"user"+el._id}>
                  <TableCell> {el.level === 0 ? <Shield/> : <Route/>  } </TableCell>
                  <TableCell>
                    <Button size="small" sx={{padding: "2px"}} component={Link} to={`/dashboard/handle-user/${el._id}`} variant="contained" color="warning"> <Edit fontSize="small"/> </Button>
                  </TableCell>
                  <TableCell>{el.email === "" ? el.user : el.email ?? el.user}</TableCell>
                  <TableCell>{el.phone}</TableCell>
                  <TableCell> <Button size="small" sx={padd0} variant="contained" color="info" onClick={() => goToUserList(el._id!)}> LISTA </Button> </TableCell>
                  <TableCell> <Button size="small" sx={padd0} variant="contained" color="inherit"> Gastos </Button> </TableCell>
                  <TableCell> <Button size="small" sx={padd0} variant="contained" color="primary" component={Link} to={`/dashboard/closure/${el._id}`}> 
                    Cuadre 
                  </Button> </TableCell>
                  <TableCell> <Button size="small" sx={padd0} variant="contained" color="secondary"> Terminadas </Button> </TableCell>
                </TableRow>
              )
            })}
          </TableBody>

        </Table>
      </TableContainer>
    </>
  )
}